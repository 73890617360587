export interface FreeSnapshotProps {
  error: string | undefined;
  sk: string | undefined;
}

export const formatTextToSlug = (text: string): string => {
  // Convert text to lowercase
  let slug = text?.toLowerCase();

  // Replace spaces with hyphens
  slug = slug?.replace(/\s+/g, "-");

  // Remove special characters
  slug = slug?.replace(/[^\w-]+/g, "");

  return slug;
};

export const extractTickerFromArticleSlug = (slug: string): string | null => {
  // Split the string by comma (,)
  const parts = slug.split(",");

  // If the string has at least one part after splitting
  if (parts.length > 0) {
    // Return the first part (the first word)
    return parts[0].trim(); // Trim any leading or trailing whitespace
  }

  // If the string is empty or does not contain any word, return null
  return null;
};

export const extractSlugFromArticleSlug = (slug: string): string | null => {
  // Split the string by comma (,)
  const parts = slug.split(",");

  // If the string has at least one part after splitting
  if (parts.length > 0) {
    // Return the first part (the first word)
    return parts[parts.length - 1].trim(); // Trim any leading or trailing whitespace
  }

  // If the string is empty or does not contain any word, return null
  return null;
};
