import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { useCallback, useEffect, useRef, useState } from "react";
import { HiOutlineMagnifyingGlass as SearchIcon } from "react-icons/hi2";
import { HiOutlineXMark as XIcon } from "react-icons/hi2";

import { useDebounce } from "@/hooks/useDebounce";
import useIsMobile from "@/hooks/useIsMobileDevice";

import { Company } from "./Company";

interface Props {
  className?: string;
}

export const SearchInputExpandable = ({ className }: Props) => {
  const isMobile = useIsMobile();
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [isSearchComponentVisible, setIsSearchComponentVisible] =
    useState(false);
  const refExpandableSearchBar = useRef<HTMLDivElement | null>(null);
  const refExpandablePopover = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: Event) => {
    const target = event.target as HTMLDivElement;
    if (
      refExpandableSearchBar.current &&
      refExpandablePopover.current &&
      !(
        refExpandableSearchBar.current.contains(target) ||
        refExpandablePopover.current.contains(target)
      )
    ) {
      event.stopPropagation();
      setIsSearchComponentVisible(false);
      setShowInput(false);
    }
  }, []);

  const handleSearchIconClick = () => {
    setShowInput(true);
    setIsSearchComponentVisible(true);
    setSearchTerm("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  const handleInputBlur = () => {
    if (
      !isSearchComponentVisible &&
      !inputRef.current?.contains(document.activeElement)
    ) {
      setIsSearchComponentVisible(false);
      setShowInput(false);
      setSearchTerm("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside, isSearchComponentVisible]);

  const handleCloseSearch = () => {
    setIsSearchComponentVisible(false);
    setShowInput(false);
    setSearchTerm("");
  };

  return (
    <div className="flex gap-2 relative flex-col items-start lg:flex-row lg:items-center">
      <Popover
        as="div"
        className={`relative right-0 ${
          showInput && isSearchComponentVisible
            ? "w-full"
            : "w-auto flex justify-start lg:w-64 lg:justify-end"
        } flex z-50`}
      >
        <div
          ref={refExpandableSearchBar}
          role="searchbox"
          aria-label="Search"
          className={`flex items-center py-1 relative ${
            showInput && isSearchComponentVisible
              ? "bg-fi-black-secondary px-2 w-full rounded-full"
              : "bg-black w-auto rounded lg:bg-fi-black-secondary lg:w-36 lg:rounded-full"
          }`}
        >
          <input
            ref={inputRef}
            type="search"
            placeholder="Search..."
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.currentTarget.value);
            }}
            onBlur={handleInputBlur}
            data-testid="expandable-search"
            className={`${
              !showInput && !isSearchComponentVisible ? "hidden" : "w-full"
            } order-1 h-auto text-sm placeholder-gray-400 lg:pr-2 pr-0 bg-fi-black-secondary border-0 focus:outline-0 focus:ring-0 text-white transition-all duration-200 ease-in-out ${className}`}
          />
          {isMobile && isSearchComponentVisible ? (
            <XIcon
              className="text-3xl w-8 text-white cursor-pointer ml-0 mr-2 pr-2 border-r border-gray-divider -order-1 scale-95 hover:scale-100 transition-all duration-200 ease-in-out"
              onClick={handleCloseSearch}
            />
          ) : (
            <SearchIcon
              data-testid="searchIcon"
              className="text-2xl text-white pr-2 lg:pr-0 cursor-pointer ml-0 mr-2 border-r lg:ml-2 lg:mr-1 lg:border-r-0 border-gray-divider -order-1 scale-95 hover:scale-100 transition-all duration-200 ease-in-out"
              onClick={handleSearchIconClick}
            />
          )}
          <Link
            href="/coveragelist"
            data-testid="view-all-coverage"
            className={`${
              !showInput ? "hidden lg:inline-flex" : "inline-flex"
            } order-2 absolute right-2 button px-2 py-2 min-w-24 w-24 bg-dolphin-tertiary border-none rounded-full hover:bg-dolphin-tertiary/75 hover:text-white transition-all duration-200 text-sm font-medium h-6 items-center justify-center cursor-pointer`}
          >
            View All
          </Link>
        </div>
        <Transition
          show={isSearchComponentVisible}
          enter="transition ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute top-12 right-0 w-full flex flex-col min-w-[210px] mt-1 h-[19.5rem] py-2 rounded shadow-xl bg-white text-fi-black"
            ref={refExpandablePopover}
          >
            <div className="flex flex-1 w-full">
              <Company
                isSearchLite={false}
                isHomeSearch={false}
                isFolikoInsights={true}
                close={handleCloseSearch}
                searchTerm={debouncedSearchTerm}
                isPublic={true}
              />
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};
